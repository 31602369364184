* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    font-family: -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #000;
}

::-webkit-scrollbar-thumb {
    background: #424242;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.sidenav {
    height: 100%;
    /* 100% Full-height */
    width: 0;
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Stay on top */
    top: 0;
    /* Stay at the top */
    left: 0;
    background-color: rgba(0, 0, 0, 0.527);
    /* Black*/
    overflow-x: hidden;
    /* Disable horizontal scroll */
    padding-top: 60px;
    /* Place content 60px from the top */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in the sidenav */
}

.sidenav a {
    padding: 4px 8px 0px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #424242;
    display: block;
    transition: 0.3s;
    font-family: -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif;
    ;
}

.sidenav h1 {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #727272;
    font-family: -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif;
    ;
}

.sidenav a:hover {
    color: white;
    letter-spacing: .1em;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}

.opennav {
    position: fixed;
    top: 1em;
    left: 1em;
    height: 2em;
    width: 2em;
    color: #424242;
    transition: 0.2s;
}

#opennnav {
    font-size: 1.2em;
}

.opennav:hover {
    color: white;
    height: 2.3em;
    width: 2.3em;
}

html {
    scroll-behavior: smooth;
}

canvas {
    position: fixed;
    top: 0;
    left: 0;
}

main {
    position: absolute;
    width: 100%;
}

iframe {
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
    color: white;
}

ul {
    color: white;
}

em {
    color: white;
}

section {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#home {
    display: flex;
    justify-content: center;
    align-items: center;
}

#home img {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-user-drag: none;
    width: 70%;
}

.content {
    max-height: 90%;
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.posters {
    height: 600px;
    margin: 0;
    display: grid;
    grid-template-rows: 500px 100px;
    grid-template-columns: 1fr 30px 30px 1fr;
    align-items: center;
    justify-items: center;
}

.player-wrapper {
    margin: 0 auto;
    width: 70%;
    max-height: 90%;
    
}

main#carousel {
    grid-row: 1 / 2;
    grid-column: 1 / 8;
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transform-style: preserve-3d;
    perspective: 600px;
    --items: 5;
    --middle: 3;
    --position: 1;
    pointer-events: none;
}

div.item {
    position: absolute;
    width: 20em;
    height: 30em;
    --r: calc(var(--position) - var(--offset));
    --abs: max(calc(var(--r) * -1), var(--r));
    transition: all 0.25s ease-in-out;
    transform: rotateY(calc(-10deg * var(--r))) translateX(calc(-300px * var(--r)));
    z-index: calc((var(--position) - var(--abs)));
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

div.item:nth-of-type(1) {
    --offset: 1;
    background-image: url(./RUSK_POPUP.png);

}

div.item:nth-of-type(2) {
    --offset: 2;
    background-image: url(./RUSK_POPUP.png);

}

input:nth-of-type(1) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

input:nth-of-type(1):checked~main#carousel {
    --position: 1;
}

input:nth-of-type(2) {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}

input:nth-of-type(2):checked~main#carousel {
    --position: 2;
}

#previous .content iframe {
    width: 60%;
    height: 20%;
    padding-top: .5em;
    padding-bottom: .5em;
}

#upcoming .content p {
    color: white;
    text-align: center;
    font-size: 2em;
    font-style: italic;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    padding-top: .1em;
    padding-bottom: .1em;
    padding-left: 1em;
    padding-right: 1em;
    background-color: rgba(0, 0, 0, 0.363);
}

#upcoming .content p a:hover {
    transition: all 0.1s ease-in-out;
    transform: scale(1.1);
}

#upcoming .content p a:hover {
    letter-spacing: .1em;
}

#work .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-shrink: 1;
}

#work .content .item {
    min-width: max-content;
    min-height: 1em;
    max-height: 10em;
    width: 20%;
    height: 20%;
    margin: 1%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    background-color: #ff70a6;
    border-radius: 1em;
    transition: all 0.2s ease-in-out;
    background-size: cover;
    background-position: center;
}

#work .content .item h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    background-color: rgba(0, 0, 0, 0.411);
    width: 100%;
    height: 15%;
}

#work .content .item:nth-child(1) {
    background-image: url(./fuckyoutoo.jpg);
}

#work .content .item:nth-child(2) {
    background-image: url(./mapbot.png);
}

#work .content .item:nth-child(3) {
    background-image: url(./barelyvisible.png);
}

#work .content .item:nth-child(4) {
    background-image: url(./obsguide.png);
}

#work .content .item:nth-child(5) {
    background-image: url(./playmore.png);
}

#work .content .item:nth-child(6) {
    background-image: url(./project1.png);
}

#work .content .item:nth-child(7) {
    background-image: url(./ScrumBoomer.png);
}

#work .content .item:nth-child(8) {
    background-image: url(./spinshare.jpg);
}

#work .content .item:nth-child(9) {
    background-image: url(./sssopanel.jpg);
}


#work .content .item:hover {
    transition: all 0.1s ease-in-out;
    transform: scale(1.1);
}

#contact .content li {
    list-style-type: none;
    text-align: center;
    font-size: 2em;
    font-style: italic;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    padding-top: .1em;
    padding-bottom: .1em;
    padding-left: 1em;
    padding-right: 1em;
    background-color: rgba(0, 0, 0, 0.363);
}

#contact .content li:hover {
    letter-spacing: .1em;
}


.down {
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
}

.down a {
    transition: all 0.2s ease-in-out;
    color: #424242;
}

.up {
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
}

.up a {
    transition: all 0.2s ease-in-out;
    color: #424242;
}

.down a:hover {
    color: white;
}

.up a:hover {
    color: white;
}


#house {
    font-size: .85em;
}