* {
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #424242;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidenav {
  height: 100%;
  width: 0;
  z-index: 1;
  background-color: #00000086;
  padding-top: 60px;
  transition: all .5s;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
}

.sidenav a {
  color: #424242;
  padding: 4px 8px 0 32px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 25px;
  text-decoration: none;
  transition: all .3s;
  display: block;
}

.sidenav h1 {
  color: #727272;
  padding: 8px 8px 8px 32px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 25px;
  text-decoration: none;
}

.sidenav a:hover {
  color: #fff;
  letter-spacing: .1em;
}

.sidenav .closebtn {
  margin-left: 50px;
  font-size: 36px;
  position: absolute;
  top: 0;
  right: 25px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.opennav {
  height: 2em;
  width: 2em;
  color: #424242;
  transition: all .2s;
  position: fixed;
  top: 1em;
  left: 1em;
}

#opennnav {
  font-size: 1.2em;
}

.opennav:hover {
  color: #fff;
  height: 2.3em;
  width: 2.3em;
}

html {
  scroll-behavior: smooth;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
}

main {
  width: 100%;
  position: absolute;
}

iframe {
  width: 100%;
  height: 100%;
}

a {
  color: #fff;
  text-decoration: none;
}

ul, em {
  color: #fff;
}

section {
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#home {
  justify-content: center;
  align-items: center;
  display: flex;
}

#home img {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  width: 70%;
}

.content {
  max-height: 90%;
  height: 90%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.posters {
  height: 600px;
  grid-template-rows: 500px 100px;
  grid-template-columns: 1fr 30px 30px 1fr;
  place-items: center;
  margin: 0;
  display: grid;
}

.player-wrapper {
  width: 70%;
  max-height: 90%;
  margin: 0 auto;
}

main#carousel {
  width: 100%;
  height: 500px;
  transform-style: preserve-3d;
  perspective: 600px;
  --items: 5;
  --middle: 3;
  --position: 1;
  pointer-events: none;
  grid-area: 1 / 1 / 2 / 8;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

div.item {
  width: 20em;
  height: 30em;
  --r: calc(var(--position)  - var(--offset));
  --abs: max(calc(var(--r) * -1), var(--r));
  transform: rotateY(calc(-10deg * var(--r)) ) translateX(calc(-300px * var(--r)) );
  z-index: calc((var(--position)  - var(--abs)) );
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all .25s ease-in-out;
  position: absolute;
}

div.item:nth-of-type(1) {
  --offset: 1;
  background-image: url("RUSK_POPUP.c3ac5c81.png");
}

div.item:nth-of-type(2) {
  --offset: 2;
  background-image: url("RUSK_POPUP.c3ac5c81.png");
}

input:nth-of-type(1) {
  grid-area: 2 / 2 / 3 / 3;
}

input:nth-of-type(1):checked ~ main#carousel {
  --position: 1;
}

input:nth-of-type(2) {
  grid-area: 2 / 3 / 3 / 4;
}

input:nth-of-type(2):checked ~ main#carousel {
  --position: 2;
}

#previous .content iframe {
  width: 60%;
  height: 20%;
  padding-top: .5em;
  padding-bottom: .5em;
}

#upcoming .content p {
  color: #fff;
  text-align: center;
  background-color: #0000005d;
  padding: .1em 1em;
  font-size: 2em;
  font-style: italic;
  text-decoration: none;
  transition: all .2s ease-in-out;
}

#upcoming .content p a:hover {
  letter-spacing: .1em;
  transition: all .1s ease-in-out;
  transform: scale(1.1);
}

#work .content {
  flex-flow: wrap;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

#work .content .item {
  min-width: max-content;
  min-height: 1em;
  max-height: 10em;
  width: 20%;
  height: 20%;
  background-color: #ff70a6;
  background-position: center;
  background-size: cover;
  border-radius: 1em;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  margin: 1%;
  transition: all .2s ease-in-out;
  display: flex;
}

#work .content .item h1 {
  width: 100%;
  height: 15%;
  background-color: #00000069;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  display: flex;
}

#work .content .item:nth-child(1) {
  background-image: url("fuckyoutoo.c132808f.jpg");
}

#work .content .item:nth-child(2) {
  background-image: url("mapbot.5e870994.png");
}

#work .content .item:nth-child(3) {
  background-image: url("barelyvisible.afc46e95.png");
}

#work .content .item:nth-child(4) {
  background-image: url("obsguide.62abf238.png");
}

#work .content .item:nth-child(5) {
  background-image: url("playmore.287eae3a.png");
}

#work .content .item:nth-child(6) {
  background-image: url("project1.3cd77d15.png");
}

#work .content .item:nth-child(7) {
  background-image: url("ScrumBoomer.d965da2b.png");
}

#work .content .item:nth-child(8) {
  background-image: url("spinshare.ecdea852.jpg");
}

#work .content .item:nth-child(9) {
  background-image: url("sssopanel.9a6ab82c.jpg");
}

#work .content .item:hover {
  transition: all .1s ease-in-out;
  transform: scale(1.1);
}

#contact .content li {
  text-align: center;
  background-color: #0000005d;
  padding: .1em 1em;
  font-size: 2em;
  font-style: italic;
  text-decoration: none;
  list-style-type: none;
  transition: all .2s ease-in-out;
}

#contact .content li:hover {
  letter-spacing: .1em;
}

.down {
  height: 5%;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  display: flex;
}

.down a {
  color: #424242;
  transition: all .2s ease-in-out;
}

.up {
  height: 5%;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  display: flex;
}

.up a {
  color: #424242;
  transition: all .2s ease-in-out;
}

.down a:hover, .up a:hover {
  color: #fff;
}

#house {
  font-size: .85em;
}

/*# sourceMappingURL=index.2952ccf8.css.map */
